<template>
  <v-dialog v-model="dialog" persistent width="750">
    <v-card>
      <v-card-title class="text-h5" v-if="action === 'Create'"
        >Create Operator</v-card-title
      >
      <v-card-title class="text-h5" v-else>Edit Operator</v-card-title>

      <v-card-text>
        <v-form ref="operatorForm">
          <v-row>
            <v-col md="6" cols="12" v-if="action === 'Create'">
              <label>First name</label>
              <v-text-field
                label="Enter first name"
                outlined
                dense
                single-line
                hide-details="auto"
                v-model="operatorForm.first_name"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" v-if="action === 'Create'">
              <label>Last name</label>
              <v-text-field
                label="Enter last name"
                outlined
                dense
                single-line
                hide-details="auto"
                v-model="operatorForm.last_name"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <label>Username</label>
              <v-text-field
                label="Enter username"
                outlined
                dense
                single-line
                :rules="rules"
                hide-details="auto"
                v-model="operatorForm.username"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12" v-if="action === 'Create'">
              <label>Email ID</label>
              <v-text-field
                label="Enter email id"
                outlined
                dense
                single-line
                hide-details="auto"
                :rules="emailRules"
                v-model="operatorForm.email"
              ></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <label>Department</label>
              <v-text-field
                label="Enter department"
                outlined
                dense
                single-line
                hide-details="auto"
                v-model="operatorForm.department"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <label>Phone number</label>
              <v-text-field
                label="Enter phone number"
                outlined
                dense
                single-line
                hide-details="auto"
                v-model="operatorForm.phone_number"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <label>Address</label>
              <v-text-field
                label="Enter address"
                outlined
                dense
                single-line
                hide-details="auto"
                v-model="operatorForm.address"
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <label>Zip code</label>
              <v-text-field
                label="Enter zip code"
                outlined
                dense
                single-line
                hide-details="auto"
                v-model="operatorForm.zip_code"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="var(--primary)"
          dark
          :loading="loading"
          @click="createAdmin"
          v-if="action === 'Create'"
        >
          Create</v-btn
        >
        <v-btn
          color="var(--primary)"
          dark
          :loading="loading"
          @click="editAdmin"
          v-else
        >
          Edit</v-btn
        >
        <v-btn color="grey darken-1" dark @click="close"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['dialog', 'action', 'operator'],
  data: () => ({
    operatorForm: {
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      address: '',
      department: '',
      zip_code: '',
    },
    rules: [(v) => !!v || 'Field is required '],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+/.test(v) || 'E-mail is invalid',
    ],
    loading: false,
  }),
  methods: {
    close() {
      this.$refs.operatorForm.reset()
      // reset field that is hidden as well
      this.operatorForm.user_id = ''
      this.$emit('refresh')
      this.$emit('update:dialog', false)
    },
    async createAdmin() {
      if (this.$refs.operatorForm.validate()) {
        this.loading = true
        try {
          await this.$store.dispatch('create_admin', this.operatorForm)
          this.$refs.operatorForm.reset()
          this.$root.snackbar.show({
            message: 'Admin successfully created',
            color: 'success',
          })
          this.close()
        } catch (error) {
          this.$root.snackbar.show({
            message: error.response.data.message,
            color: 'error',
          })
        } finally {
          this.loading = false
        }
      } else {
        this.$root.snackbar.show({
          message: 'Required fields are missing.',
          color: 'error',
        })
      }
    },
    async editAdmin() {
      let payload = {}
      payload.user_id = this.operatorForm.user_id
      payload.username = this.operatorForm.username
      payload.address = this.operatorForm.address
      payload.phone_number = this.operatorForm.phone_number
      payload.zip_code = this.operatorForm.zip_code
      payload.department = this.operatorForm.department
      try {
        this.loading = true
        await this.$store.dispatch('edit_admin', payload)
        this.$root.snackbar.show({
          message: 'Admin updated successfully',
          color: 'success',
        })
        this.close()
      } catch (error) {
        this.$root.snackbar.show({
          message: error.response.data.message,
          color: 'error',
        })
      } finally {
        this.loading = false
      }
    },
  },
  watch: {
    dialog: function () {
      if (this.dialog && this.action !== 'Create') {
        this.operatorForm.username = this.operator.username ?? ''
        this.operatorForm.user_id = this.operator.user_id ?? ''
        this.operatorForm.phone_number = this.operator.phone_number ?? ''
        this.operatorForm.address = this.operator.address ?? ''
        this.operatorForm.zip_code = this.operator.zip_code ?? ''
        this.operatorForm.department = this.operator.dapartment ?? ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text {
  padding-top: 15px;
  padding-bottom: 15px;
}
label {
  margin-bottom: 5px;
  display: inline-block;
}
</style>